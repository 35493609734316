<template>
    <div>
        <label>{{ labeltext }}</label>
        <div class="graphical-select">
            <select class="inputForm custom-select" 
                v-model="value"
                :name="name"
                @input="$emit('onchange', $event.target.value)"
            >
                <option selected :value="title">{{ title }}</option>
                <option v-for="(option, i) of options" :key="i" :value="option">
                    {{ option }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    name: "SelectBox",
    props: {
        labeltext: String,
        title: String,
        name: String,
        value: String,
        options: {
            type: Set,
            required: true,
        },
        default: {
            type: String,
            required: false,
            default: null,
        },
        tabindex: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            selected: this.default ?
                this.default :
                this.options.length > 0 ?
                this.options[0] :
                null,
            open: false,
        };
    },
    mounted() {

        // this.$emit("input", this.selected);
    },
    methods: {
        changeFoo(e) {

            this.$emit('changeFoo', e.target.value);
        }
    },
   
};
</script>

<style scoped>
    .inputGroup .select label {
        text-align: left;
        font-size: 13px;
        padding-bottom: 9px;
        display: flex;
        font-family: 'Nissan Bold';

    }

    .graphical-select {
        height: 40px;
        position: relative;
    }

    .custom-select {
        position: relative;
        bottom: 0;
        left: 0;
        padding: 0 15px;
        position: absolute;
        right: 0;
        top: 0;
        /* height: 100%; */
        width: 100%;
        font-size: inherit;
        color: #343434;
        background: #fff;
        font-size: 14px;
        resize: none;
        border: 1px solid #666;
        font-family: "Nissan Light";
        font-weight: normal;
        border-radius: 0;
        -moz-appearance: none;
        /* Firefox */
        -webkit-appearance: none;
        appearance: none;
        /* background: transparent; */
        /*     
    background-image: url("data:image/svg+xml;utf8,<svg class='svg-icon'  width='16' fill='c60b38' style='width: 1em; height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg'>  <path  d='M533.215 609.227c-8.956-12.502-7.819-30.005 3.412-41.236L819.47 285.148l90.51 90.51c12.497 12.497 12.497 32.758 0 45.255l-282.54 282.54 0.304 0.303-90.51 90.51c-12.496 12.496-32.757 12.496-45.254 0L96 398.285l90.51-90.51c12.496-12.497 32.758-12.497 45.255 0l301.45 301.45z' /></svg>");
    background-repeat: no-repeat;
    background-position-x: 99%;
    background-position-y: 50%; */
    }

    .graphical-select::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: 10px 0;
        background-image: url(../../../assets/img/down-arrow-red.png);
        background-size: 14px;
        background-repeat: no-repeat;
        background-position-x: 50%;
        background-position-y: 50%;
        /* font-size: 25px;  */
        border-left: 1px solid #565656;
        /* background: #0e7b53; */
        color: #fff;
        padding: 11px 20px;
        pointer-events: none;
    }

    select:hover,
    select:focus {
        border-color: #c3002f;
        box-shadow: none;
        outline: none;
    }
</style>