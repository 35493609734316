<script setup>
    import DoubleRangeSlider from "../Inputs/RangeSlider.vue";

    import ArrowDown from './UI/ArrowDown.vue';
    import SelectBox from './UI/SelectBox.vue';
    import InputRangeSlider from './UI/InputRange.vue';
    
</script>

<template>
    <div class="mt-5">

        <div>
            <p class="mutedText text-left">
                Filters have been applied to your search results.
                <a v-on:click="isHidden = !isHidden">Refine your search.</a>
            </p>

            <button class="searchBtn" v-on:click="isHidden = !isHidden">
                <span v-if="isHidden">REFINE YOUR SEARCH</span>
                <span v-if="!isHidden">HIDE SEARCH</span>
                <ArrowDown />
            </button>
        </div>

        <Transition mode="out-in">

            <div v-if="!isHidden" class="mt-3">
                <div class="searchSlider">
                    <!-- <div style="width:30%">
                    <DoubleRangeSlider :min="min" :max="max"   @change-foo="changepricerange"
                    label="Price"
                        labeltext="max R %s"
                    
                    ></DoubleRangeSlider>
                    </div> -->
                    <InputRangeSlider key="1" class="range" label="Price" labeltext="R" 
                        :minvalue="getFilterData.priceMin" 
                        :maxvalue="getFilterData.priceMax" 
                        @onchange="setFilter($event, 'priceSliderValue')"
                    />

                    <InputRangeSlider key="2" class="range" label="Mileage" labeltext="KM" 
                        :minvalue="getFilterData.mileageMin" 
                        :maxvalue="getFilterData.mileageMax" 
                        @onchange="setFilter($event, 'mileageSliderValue')"
                    />

                    <InputRangeSlider key="3" class="range" label="Model year" labeltext="" 
                        :minvalue="getFilterData.yearMin" 
                        :maxvalue="getFilterData.yearMax" 
                        @onchange="setFilter($event, 'yearSliderValue')"
                    />

                </div>
                <div class="selectInputs">
                    <div class="inputGroup">

                        <SelectBox 
                            labeltext="Make" 
                            class="select" 
                            name="make"  
                            title="All Makes" 
                            :options="getFilterData.allMakes" 
                            :value="inputs.make" 
                            @onchange="setFilter($event, 'make')"
                        />

                        <SelectBox 
                            labeltext="Body style" 
                            class="select" 
                            name="bodyType"  
                            title="All Body" 
                            :options="getFilterData.allBodyTypes" 
                            :value="inputs.bodyType" 
                            @onchange="setFilter($event, 'bodyType')"
                        />

                        <SelectBox 
                            labeltext="Model" 
                            class="select" 
                            name="model"  
                            title="All Models" 
                            :options="getFilterData.allModels" 
                            :value="inputs.model" 
                            @onchange="setFilter($event, 'model')"
                        />

                        <SelectBox 
                            labeltext="Transmission" 
                            class="select" 
                            title="All Transmission" 
                            name="transmissions"  
                            :options="getFilterData.allTransmissions" 
                            :value="inputs.transmissions" 
                            @onchange="setFilter($event, 'transmissions')"
                        />

                    </div>
                    <div class="inputGroup">
                     
                        <SelectBox 
                            labeltext="Colour" 
                            class="select" 
                            name="colour"  
                            title="All Colour" 
                            :options="getFilterData.allColours" 
                            :value="inputs.colour" 
                            @onchange="setFilter($event, 'colour')"
                        />

                        <SelectBox 
                            labeltext="Engine" 
                            class="select" 
                            name="engine" 
                            title="All Engine" 
                            :options="getFilterData.allEngine" 
                            :value="inputs.engine" 
                            @onchange="setFilter($event, 'engine')" 
                        />

                        <SelectBox 
                            labeltext="Dealers" 
                            class="select" 
                            name="dealer" 
                            title="All Dealers" 
                            :options="getFilterData.allDealers" 
                            :value="inputs.dealer" 
                            @onchange="setFilter($event, 'dealer')" 
                        />

                    </div>
                </div>
            </div>

        </Transition>
    </div>

</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { adobeAnalyticCode } from "../../analytics.js";

export default {
    name: "FilterPanel",
    props: {

    },
    components: {
        DoubleRangeSlider
    },
    computed: {

        ...mapGetters(["getFilterData"]),
    },
    data() {
        return {
            inputs: {
                make: 'All Makes',
                model: 'All Models',
                bodyType: 'All Body',
                transmissions: 'All Transmission',
                colour: 'All Colour',
                engine: 'All Engine',
                priceSliderValue: [],
                mileageSliderValue: [],
                yearSliderValue: [],
                sortByPriceValue: 'Ascending',
                dealer: 'All Dealers'
            },
            numParagraphs: 10,
            min: 0,
            max: 10,
            isHidden: true,
        };
    },
    mounted(){

        setTimeout(() => {
            
            this.inputs.priceSliderValue = [this.getFilterData.priceMin, this.getFilterData.priceMax];
            // console.log(this.inputs.priceSliderValue, 'here dude');
            this.inputs.mileageSliderValue = [this.getFilterData.mileageMin, this.getFilterData.mileageMax];
            this.inputs.yearSliderValue = [this.getFilterData.yearMin, this.getFilterData.yearMax];
        }, 2000);
        
    },
    methods: {

        setFilter(inputValue, inputName){

            // console.log(inputValue, 'input value');
            // console.log(inputName, 'input name');
            this.inputs[inputName] = inputValue;

            // if(inputName == 'priceSliderValue' || inputName == 'mileageSliderValue' || inputName == 'yearSliderValue'){
            //     // console.log(inputValue[0] + '-----' + inputValue[1], 'slider value');
            //     this.inputs[inputName] = [inputValue[0], inputValue[1]];
            // }else{
            //     this.inputs[inputName] = inputValue;
            // }

            if(inputName == 'make'){
                
                this.inputs.model = 'All Models';
                this.inputs.bodyType = 'All Body';
                this.inputs.transmissions = 'All Transmission';
                this.inputs.colour = 'All Colour';
                this.inputs.engine = 'All Engine';
                this.inputs.dealer = 'All Dealers';
            }
            // console.log(this.inputs, 'all input values');

            adobeAnalyticCode({
                'field_type': "vehicle-filter",
                'field_name': inputName,
                'field_value': inputValue,
            });

            this.$store.dispatch('setFilter', { 
                inputs: this.inputs, 
                selectedInput: inputName
            });
        },
    }
};
</script>

<style scoped>
    .selectInputs {
        margin-top: 2em;
    }
    .searchSlider {
        display: flex;
    }
    .searchSlider .range {
        width: 31%;
    }
    .inputGroup {
        display: flex;
        flex-direction: row;
    }

    .selectInputs .inputGroup {
        margin-bottom: 1.2em;
    }

    @media (min-width: 768px) and (max-width: 979px) {
        .inputGroup {
            /* display: flex; */
            flex-direction: column;
        }
    }

    .select {
        width: 100%;
        padding-right: 20px;
    }

    .searchBtn {
        background: #eee;
        color: #000;
        width: 30%;
        padding: 12px 14px 12px 20px;
        border: none;
        cursor: pointer;
        display: flex;
        /* font-size: inherit; */
        line-height: 1em;
        position: relative;
        text-transform: uppercase;
        text-decoration: none;
        align-items: center;
        text-align: left;
        font-size: 14px;
        flex-direction: row;
        justify-content: space-between;
        font-family: "Nissan Light";
    }


    .searchBtn svg {
        fill: #c3002f;
        font-size: 18px;
    }

    .mutedText {
        font-size: 13px;
        font-family: "Nissan Bold";
        text-align: left;
        margin-bottom: 3px;
    }

    /* we will explain what these classes do next! */
    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.3s ease-in-out;
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }

    @media only screen and (max-width: 600px) {
        .searchBtn {
            width: 100% !important;
        }

        .inputGroup {

            flex-direction: column;
        }

    }
</style>