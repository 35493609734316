// console.log('Adobe Analytics Started...');

var isFormPage = false;
var isFormFieldHasError = false;
var isFormSubmitSuccessful = false;
var firstTime = false;

var category = '';
var model = '';
var vehicleid = '';
var language = '';
var version_name = '';

var pagename = "nissan-star-preowned";
var formName = "used cars";
var pageType = "star-preowned";

var invalidInputFields = "";

function adobeAnalyticCode(allData) {

    // console.log(allData, 'getting data');

    var field_type = "";
    var field_name = "";
    var field_value = "";

    if(allData.field_type == "vehicle-filter"){

        field_type = allData.field_type;
        field_name = allData.field_name;
        field_value = allData.field_value;
    }

    if(allData.field_type == "vehicle-form"){

        isFormPage = true;
        pagename = "form|enquiry";
        formName = "used cars";
        pageType = "form_page";

        isFormFieldHasError = allData.isFormFieldHasError;

        
        if(allData.failedInputFields){

            // console.log(allData.failedInputFields);

            var inputname = "", inputemail = "", inputphone = "", inputdealer = "";

            inputname = ((allData.failedInputFields.name == '' ? '' : allData.failedInputFields.name+'|') ?? "");
            inputemail = ((allData.failedInputFields.email == '' ? '' : allData.failedInputFields.email+'|') ?? "");
            inputphone = ((allData.failedInputFields.phone == '' ? '' : allData.failedInputFields.phone+'|') ?? "");
            inputdealer = ((allData.failedInputFields.dealer == '' ? '' : allData.failedInputFields.dealer) ?? "");
           
            invalidInputFields = inputname + inputemail + inputphone + inputdealer
        }
    }

    category = allData.category ?? "";
    model = ((allData.make ?? "") + " " + (allData.model ?? "")) ?? "";
    vehicleid = allData.vehicleid ?? "";
    language = allData.language ?? 'EN';
    version_name = allData.version_name ?? "";
    
    if(allData.isFormSubmitSuccessful == true){

        isFormSubmitSuccessful = allData.isFormSubmitSuccessful;
    }


    var s;
    // var host = window.location.hostname;
    // var url = window.location.hostname.split("/");
    var referer = document.referrer;
    referer.replace(".html", "");
    // var previous_page = referer.split("/");
    // var startform = false;
    // var language = "en";
    var full_url = window.location.href;
    var device = "medium";
    var screen_resolution = window.screen.width + "x" + window.screen.height;
    // var screen_size = window.innerWidth + "x" + window.innerHeight;
    var loading_time = "";
    var today = new Date();
    var time = today.toLocaleTimeString().replace("AM", "").replace("PM", "");
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
    var time_stamp = today + " " + time;
    // var dt = new Date(new Date().toString().split("GMT")[0] + " UTC").toISOString();
    var date = new Date();
    var time_stamp2 = date.toISOString();
    var platform = "Helios|" + time_stamp2;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        device = "small";
    }
    var before_loadtime = new Date().getTime();
    window.onload = Pageloadtime;

    function Pageloadtime() {
        var aftr_loadtime = new Date().getTime();
        // Time calculating in seconds
        var pgloadtime = (aftr_loadtime - before_loadtime) / 1000;
        loading_time = pgloadtime.toFixed(1) + " seconds";
        //document.getElementById("loadtime").innerHTML = "Pgae load time is <font color='red'><b>" + pgloadtime + "</b></font> Seconds";
    }

    s = window._satellite.getToolsByType("sc")[0].getS();

    s.account = "nissannsadealersprod"; //account
    s.currencyCode = "ZAR"; //Currency Code
    s.pageName = pagename; //pagename


    s.eVar1 = pagename;
    s.prop1 = pagename; //$scope.getPreviousPage();

    s.eVar2 = "star-preowned|"; //sub section
    s.prop2 = "star-preowned|"; //sub section

    s.eVar3 = "star-preowned||"; //Sub sub section
    s.prop3 = "star-preowned||"; //Sub sub section

    s.eVar4 = category; //category eg: PASSENGER CARS
    s.prop4 = category; //category eg: PASSENGER CARS

    s.eVar5 = model; //model eg: MAXIMA
    s.prop5 = model; //model eg: MAXIMA 

    s.eVar6 = vehicleid; //vehicleid eg: A36 (graden code) 
    s.prop6 = vehicleid; //vehicleid eg: A36 (graden code)

    s.eVar8 = language; //Language
    s.prop8 = language; //Language

    s.eVar9 = "ZA"; //Country code
    s.prop9 = "ZA"; //Country code

    s.eVar10 = "nissan"; //Brand
    s.prop10 = "nissan"; //Brand

    s.eVar12 = "star-preowned"; //$scope.getPreviousPage(); //Brand

    s.eVar21 = pageType; //page type
    s.eVar29 = "star-Preowned Tool"; //Vehicle : page name
    s.eVar35 = "logged out"; //login status
    
    s.eVar40 = loading_time; //pre loading time
    s.prop40 = loading_time; //pre loading time
    s.eVar44 = device + "|" + screen_resolution; //device & screen resolution
    s.eVar45 = device; //screen size
    s.prop45 = "Nissan Helios AMI"; //screen size
    s.prop46 = "Nissan ZA"; //form name
    s.eVar46 = ""; //form name

    if(allData.field_type == "vehicle-filter"){
        s.eVar52 = field_type + "|" + field_name + "|" + field_value;
    }
    s.prop52 = s.marketingCloudVisitorID; //visitor ID

    s.eVar54 = full_url; //component id
    s.prop54 = full_url; //component id
    
    s.eVar61 = platform + "|" + language; //[platform]
    s.eVar63 = time_stamp; //date and time

    s.events = "";

    if (!firstTime) {
        s.events = "";
        firstTime = true;
    }

        
    if (allData.version_name != null) {
        s.eVar55 = version_name; //model eg: MAXIMA SV
    }

    if(isFormPage){

        s.eVar14 = pagename;
        s.prop14 = pagename;

        s.eVar58 = "lead";
        s.prop58 = "lead";

        s.eVar60 = formName;
        s.prop60 = formName;

        s.eVar64 = "C20_Dropdown_Boxes";
        s.eVar77 = pagename + pageType;
        s.eVar97 = "[scroll tracking]"; //(scroll)
        s.eVar39 = "[LEAD ID]"; //(lead_id)

        s.events = "event50";

        if(isFormFieldHasError){

            s.prop34 = invalidInputFields; //all the form field names
            s.eVar64 = "C19_Form" //on input field error
            s.eVar97 = "";
        }
        
        if(isFormSubmitSuccessful){

            s.prop34 = ''; //all the form field names
            // form_category
            s.eVar58 = "Form" //form_category
            s.prop58 = "Form" //form_category
            
            s.eVar64 = 'C26_Confirmation_Page';

            s.eVar39 = "[LEAD ID]"; //(lead_id)
            s.events = "event51, event47"; // When form is submitted

            // s.events = "event51, event47, event7"; // When form is submitted
        }
    }

    s.ta();
}

export { adobeAnalyticCode };
