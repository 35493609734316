import { createApp } from 'vue'
import App from './App.vue'

import Vuex from 'vuex';
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

import store from './store/index.js';
import router from './router'
import Paginate from "vuejs-paginate-next";
import VueLazyLoad from 'vue3-lazyload'


const app = createApp(App).use(router)
    // app.use(VueAxios, axios)
app.use(store)
    // app.use(VueRouter)
    // app.use(router)
app.use(VueLazyLoad, {
    // options...
})

app.use(Paginate)
app.use(Vuex);

app.mount('#app')


// get the url paramter name vin
// const urlParams = new URLSearchParams(window.location.search);
// const stock = urlParams.get('stock');
// // console.log(stock, "------------------")

// if (stock) {
    
//     // route to details page
//     router.push({ name: 'details', params: {
//         // model:1,id:1 ,
//         stockID: stock } })  
// }
