import axios from "axios";

// var customBaseURL = 'http://localhost/alternative/nissan-star-preowned/nissan-star-preowned/public/api.php';
var customBaseURL = 'https://preownedstar.davinci-cms.com/api.php';

const state = {
   
    cars: [],
    car: {},
    allCarsList: {},

    filterData: {}
    
}

const getters = {

    getAllCars: (state) => state.cars,
    getSelectedCar: (state) => state.car,
    
    getFilterData: (state) => state.filterData,

}

const actions = {

 
    async fetchCars({ commit, dispatch, rootState }) {

        const response = await axios.get(customBaseURL, { params: { 'checkStatus':true } });
        
        // console.log(response, 'main cars data')

        var carslistData = [];
        carslistData = response.data;

        // ------ Start - Filter Search Data -------------------
        var newAllYear = [];
        var newAllMileage = [];
        var newAllPrice = [];
        // var newAllDealers = [];
        
        carslistData.map(elet => {
            
            if(!newAllYear.includes(elet.year)){
                newAllYear.push(parseInt(elet.year));
            }
            if(!newAllMileage.includes(elet.mileageInKm)){
                newAllMileage.push(parseInt(elet.mileageInKm));
            }
            if(!newAllPrice.includes(elet.price)){
                newAllPrice.push(parseInt(elet.price));
            }
            // if(!newAllDealers.includes(elet.dealerId)){
            //     newAllDealers.push(elet.dealerContactInformation.name);
            // }
        });

        var yearMin = Math.min(...newAllYear);
        var yearMax = Math.max(...newAllYear);
        // console.log(yearMin + ' - ' + yearMax, 'asd Year');
        
        var mileageMin = Math.min(...newAllMileage);
        var mileageMax = Math.max(...newAllMileage);

        var priceMin = Math.min(...newAllPrice);
        var priceMax = Math.max(...newAllPrice);

        const allMakes = new Set(carslistData.map(ele => ele.make));
        const allModels = new Set(carslistData.map(ele => ele.model));
        const allBodyType = new Set(carslistData.map(ele => ele.bodyType));
        const allTransmission = new Set(carslistData.map(ele => ele.transmission));
        const allColour = new Set(carslistData.map(ele => ele.colour));
        const allEngine = new Set(carslistData.map(ele => ele.newUsed));
        const allDealers = new Set(carslistData.map(ele => ele.dealerContactInformation.name));

        var newfilterData = {
            allMakes: allMakes,
            allModels: allModels,
            allBodyTypes: allBodyType,
            allTransmissions: allTransmission,
            allColours: allColour,
            allEngine: allEngine,
            yearMin: yearMin,
            yearMax: yearMax,
            mileageMin: 0,
            mileageMax: mileageMax,
            priceMin: priceMin,
            priceMax: priceMax,
            allDealers: allDealers
        }
        // console.log(newfilterData, 'unique data');
        // ------ End - Filter Search Data -------------------


        commit("setCars", carslistData);
        commit("setFilterData", newfilterData);
    },

    async fetchSelectedCar({ commit, dispatch, rootState }, id) {

        const response = await axios.get(customBaseURL, { params: { 'checkStatus':true } });
        const data = response.data;

        let selected;

        Object.keys(data).forEach(key => {
            const element = data[key];

            if (element.id == id) {
                selected = element;
            }
        });

        // console.log(selected, 'single car detail');
        commit("setSelectedCar", selected);

    },

    setFilter({ commit, dispatch, rootState }, params) {

        var filter = params.inputs
        var selectedInput = params.selectedInput

        // console.log(selectedInput, 'Selected input');
        // console.log(filter, 'inner input');

        const inputs = {
            make: 'All Makes',
            model: 'All Models',
            bodyType: 'All Body',
            transmissions: 'All Transmission',
            colour: 'All Colour',
            engine: 'All Engine',
            dealer: 'All Dealers',
            priceSliderValue: [],
            mileageSliderValue: [],
            yearSliderValue: [],
            // sortByPriceValue: 'Ascending',
        }

        // console.log(filter.priceSliderValue, "priceSliderValue")

        let listcars = [];

        const cars = state.allCarsList;
        Object.keys(cars).forEach(function(key) {
            const car = cars[key];
            listcars.push(car);
        });
        // console.log(listcars, 'all cars');

        var filtercars = listcars;

        filtercars = filtercars.filter(car => {
            return (parseInt(car.price) >= filter.priceSliderValue[0] && parseInt(car.price) <= filter.priceSliderValue[1])
        })

        filtercars = filtercars.filter(car => {
            return (car.mileageInKm >= filter.mileageSliderValue[0] && car.mileageInKm <= filter.mileageSliderValue[1])
        })

        filtercars = filtercars.filter(car => {
            return (car.year >= filter.yearSliderValue[0] && car.year <= filter.yearSliderValue[1])
        })

        if (filter.make != inputs.make) {

            filtercars = filtercars.filter(car => {
                return (car.make == filter.make)
            })

            const allModels2 = new Set(filtercars.map(ele => ele.model));
            state.filterData.allModels = allModels2;

            const allBodyType2 = new Set(filtercars.map(ele => ele.bodyType));
            state.filterData.allBodyTypes = allBodyType2;

            const allColour2 = new Set(filtercars.map(ele => ele.colour));
            state.filterData.allColours = allColour2;
        }

        if (selectedInput == 'make') {

            const allModels2 = new Set(filtercars.map(ele => ele.model));
            state.filterData.allModels = allModels2;

            const allBodyType2 = new Set(filtercars.map(ele => ele.bodyType));
            state.filterData.allBodyTypes = allBodyType2;

            const allColour2 = new Set(filtercars.map(ele => ele.colour));
            state.filterData.allColours = allColour2;
        }
        // console.log(filtercars, 'asd asd asd assd');

        if (filter.model != inputs.model) {

            filtercars = filtercars.filter(car => {
                return (car.model == filter.model)
            });

            const allColour3 = new Set(filtercars.map(ele => ele.colour));
            state.filterData.allColours = allColour3;
        }

        if (selectedInput == 'model') {

            const allColour3 = new Set(filtercars.map(ele => ele.colour));
            state.filterData.allColours = allColour3;
        }


        if (filter.bodyType != inputs.bodyType) {

            filtercars = filtercars.filter(car => {
                return (car.bodyType == filter.bodyType)
            });

        }

        if (selectedInput == 'bodyType') {

            const allModels4 = new Set(filtercars.map(ele => ele.model));
            state.filterData.allModels = allModels4;

            const allColour4 = new Set(filtercars.map(ele => ele.colour));
            state.filterData.allColours = allColour4;
        }

        if (filter.dealer != inputs.dealer) {

            filtercars = filtercars.filter(car => {
                return (car.dealerContactInformation.name == filter.dealer)
            });

        }

        // console.log(filtercars)
        commit("setFilterCars", filtercars);

    },

}


const mutations = {

    setFilterCars: (state, cars) => {
        //console.log(cars, "cars");
        state.cars = cars;
    },
    setFilterData: (state, filteringData) => {
        //console.log(cars, "cars");
        state.filterData = filteringData;
    },
    setCars: (state, cars) => {
        state.cars = cars;
        state.allCarsList = cars;
    },
    setSelectedCar: (state, car) => {
        // console.log(car, "car committed");
        car.price = parseInt(car.price)
        state.car = car;
    },

};

// then need to export
export default {
    state,
    getters,
    actions,
    mutations,
};

