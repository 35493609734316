import Vuex from 'vuex';
// import Vue from 'vue';
import models from './modules/models';
import cars from './modules/cars.js';

// Load Vuex
// Vue.use(Vuex);

// Create store
export default new Vuex.Store({
    modules: {
        models,
        cars
    }
});